
import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography,Row, Col} from "antd";
import { BankOutlined } from '@ant-design/icons';
import MenuSide from "../components/menuSide";
import BankAccountDetail from "../components/BankAccountDetail"
import { AppContext } from '../App';


const { Header, Content } = Layout;
const { Title } = Typography;

const News = () => {

  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'owner'){      
      window.location.href = "/home"; 
    }
  }

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">

          <Row>
                <Col span={1} />
                <Col span={22} >
                    <Title level={3}><BankOutlined /> ข้อมูลบัญชีธนาคาร</Title>
                </Col>
                <Col span={1} />
            </Row>
            <Row>
                <Col span={1} />
                <Col span={22} >
                    <BankAccountDetail />
                </Col>
                <Col span={1} />
            </Row>
            



          </Content>
          
      </Layout>
    )
  };
  
  export default News;
  