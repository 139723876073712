import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import MenuSide from "../components/menuSide";
import ListWalletTransaction from "../components/walletTransaction"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import {WalletOutlined} from "@ant-design/icons"
import { AppContext } from '../App';

const { Title } = Typography;
const { Content } = Layout;

const Wallet = () => {
  
  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }

    const { phone } = useParams()

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={1}></Col>
                <Col span={22}><Title level={3}><WalletOutlined /> ประวัติกระเป๋าเงิน : {phone}</Title></Col>
                <Col span={1}></Col>

            </Row>
            <Row>
                <Col span={1}></Col>
                <Col span={22}><ListWalletTransaction phone={phone}/></Col>
                <Col span={1}></Col>

            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default Wallet;
  