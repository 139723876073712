import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag} from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";


import { AppContext } from '../App';



const ListWalletTransaction = (phone) => {

  const customerPhone = phone.phone
  console.log(customerPhone)
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/100),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาเลขออเดอร์`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'ออเดอร์',
      width: 100,
      dataIndex: 'orderId',
      key: 'orderId',
      // fixed: 'left',
      ...getColumnSearchProps('orderId'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {text}
        </div>
      )    
    },
    {
        title: 'ประเภทชำระ',
        width: 80,
        dataIndex: 'transactionType',
        key: 'transactionType',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text === "topup" ? "เติมเงิน" : (text === "checkout" ? "ชำระเงิน" : "")}
          </div>
        ),
    },
    {
        title: 'ยอดเงิน',
        width: 90,
        dataIndex: 'transactionAmount',
        key: 'transactionAmount',
        // fixed: 'left',
        render: (text, record) => (
          <div>
                {record.transactionType === "topup" ? <b style={{color:'green'}}>{text}</b> : (record.transactionType === "checkout" ? <b style={{color:'red'}}>{text}</b> : "")}
          </div>
        ),
    },
    {
      title: 'วันที่',
      width: 90,
      dataIndex: 'createdAt',
      key: 'createdAt',
      // fixed: 'left',
      render: (text, record) => (
        <div>
            {moment(text).format('DD/MM/YYYY H:mm')}
        </div>
      ),
    },{
      title: 'ช่องทางเติมเงิน',
      width: 90,
      dataIndex: 'paymentName',
      key: 'paymentName',
      render: (text, record) => (
        <div>
            { text ? text : ""}
        </div>
      ),
    },
    {
        title: 'ประเภทการซัก',
        width: 90,
        dataIndex: 'laundryType',
        key: 'laundryType',
        render: (text, record) => (
          <div>
             {text === 'washer' ? "ซักผ้า" : ( text === 'dryer' ? "อบผ้า" : (text === 'dryerExtendTime' ? 'ต่อเวลาอบผ้า' : text ))}
          </div>
        ),
    },
    {
        title: 'สาขา',
        width: 90,
        dataIndex: 'branch',
        key: 'branch',
        render: (text, record) => (
          <div>
             {text ? text : ""}
          </div>
        ),
    },
    {
        title: 'เครื่องที่',
        width: 90,
        dataIndex: 'machineNo',
        key: 'machineNo',
        render: (text, record) => (
          <div>
             {text ? text : ""}
          </div>
        ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getListWalletTransaction/${customerPhone}`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((transaction) => ({
        ...transaction,
        key: transaction.orderId,
      }));

      setData(adjustedData)
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 100),
        };
      });
    } catch (error) {
      console.log('error', error)
    }
  }

  const showTransactionDetail = async(phone) => {
    setOnCheckTransaction(true)
    try {
        const response = await axios.get(`${API_URL}getWalletTransaction/${phone}`, {
          headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })

        if(response.data.status === 'successful'){
            messageApi.open({
                type: 'success',
                content: 'อัปเดตรายการชำระเงินสำเร็จ',
            });
        }else{
            messageApi.open({
                type: 'warning',
                content: 'รอการชำระเงิน',
            });
        }
  
        fetchData()
      } catch (error) {
        console.log('error', error)
      }
      
    setOnCheckTransaction(false)

  }

  useEffect(() => {
    if(data.length === 0){
        fetchData()
    }
  }, [data, pagination.current, pagination.pageSize])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={(newPagination) => setPagination(newPagination)}
          scroll={{
            x: 400,
          }}
          rowKey="orderId"
        />
      ) : (
        <p>Loading</p>
      )}
    </div>
  )
}

export default ListWalletTransaction