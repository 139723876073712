import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';

import { Layout, theme, Tabs, Typography, Row, Col, Space } from "antd";
import { AccountBookOutlined  } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import ListBranchIncome from "../components/listBranchIncome"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;


const SaleReport = () => {
  
    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <Row>
                    <Col span={1} />
                    <Col span={22}>
                      <Title level={3} ><AccountBookOutlined /> รายงานยอดขาย</Title>
                    </Col>
                    <Col span={1} />

            </Row>
            <Row>
                    <Col span={1} />
                    <Col span={22}>
                      <ListBranchIncome />
                    </Col>
                    <Col span={1} />

            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default SaleReport;
  