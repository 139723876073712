import React, { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';


import MenuSide from "../components/menuSide";
import ListFanshineFeeHistory from "../components/listFanshineFeeHistory"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { AppContext } from '../App';

const { Title } = Typography;
const { Content } = Layout;

const FanshineHistoryFee = () => {
  
  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }

    const { fanshineId } = useParams()

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={1} />
                <Col span={22}><Title level={3}><DeploymentUnitOutlined /> แก้ไขค่าบริการแฟรนไชส์</Title></Col>
                <Col span={22}></Col>
                <Col span={1} />
            </Row>
            <Row>
                <Col span={1} />
                <Col span={22}><ListFanshineFeeHistory fanshineId={fanshineId} /></Col>
                <Col span={1} />
            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default FanshineHistoryFee;
  