import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';

import { Layout, theme, Tabs, Typography, Row, Col, Space, Form, Select } from "antd";
import { HomeFilled } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import IncomeToday from "../components/incomeToday"
import IncomeThisWeek from "../components/incomeThisWeek"
import ListBranchIncome from "../components/listBranchIncome.js"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('Authentication');



const Home = () => {

    const [branch, setBranch] = useState([]);
    const [form] = Form.useForm();
    const [branchSelected, setBranchSelected] = useState('all')
    const [incomeThisDay, setIncomeThisDay] = useState([])


    const getBranch = async () => {
      try {
          const response = await axios.get(`${API_URL}getBranchManager`,
              {
                  headers: { 'Authorization': `bearer ${token}` }
              })
          setBranch(response.data);  
      } catch (error) {
          console.log(error);
      }
    }

    const getIncomeToday = async (branchId) => {

      if(!branchId){branchId = ""}
      try {
          const response = await axios.get(`${API_URL}getIncomeToday/${branchId}`, {
            headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          setIncomeThisDay(response.data.incomeToday)

        } catch (error) {
          console.log('error', error)
        }
  }

    const changeBranch = async (value) => {
      setIncomeThisDay('')
      setBranchSelected(value)
      getIncomeToday(value)
    }

    useEffect(() => {
      getBranch()
      getIncomeToday(branchSelected)
    },[])

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <Row>
                    <Col span={1} />
                    <Col span={18}>
                      <Title level={3} ><HomeFilled /> รายได้โดยรวม</Title>
                    </Col>
                    <Col span={4}>

                    <Title level={3} >
                      <Form form={form} >
                        <Form.Item name="branch" label="สาขา" className='formItemWithNoMargin' style={{marginLeft:40}}>
                          <Select style={{width:150}} optionFilterProp="label" onChange={changeBranch} defaultValue="all">
                              <Select.Option key='all' value='all' label='สาขาทั้งหมด'>สาขาทั้งหมด</Select.Option>
                              {branch.map(branchItem => (
                                  <Select.Option key={branchItem.id} value={branchItem.id} label={branchItem.name}>{branchItem.name}</Select.Option>
                              ))}
                          </Select>
                        </Form.Item>  
                        </Form>  
                    </Title>
                     </Col>

      
                    <Col span={1} />

            </Row>
            <Row>
                    <Col span={1} />
                    <Col span='6'>
                        <IncomeToday branchSelected={branchSelected} incomeThisDay={incomeThisDay}/>
                    </Col>
                    <Col span={1} />
                    <Col span='15'>
                        <IncomeThisWeek branchSelectedIndex={branchSelected} incomeThisDay={incomeThisDay}/>
                    </Col>
            </Row>
            <Row>
                    <Col span={1} />
                    <Col span='22'>
                        <Title level={4} > รายได้แต่ละสาขา</Title>
                        <ListBranchIncome />
                    </Col>
                    <Col span={1} />
            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default Home;
  