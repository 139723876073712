import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Modal, Form, Select, TimePicker, Row, Col, Upload} from 'antd'
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { AppContext } from '../App';

const ListTransaction = () => {

  const { user } = useContext(AppContext);
  const { TextArea } = Input;
  const [messageApi, contextHolder] = message.useMessage();

  const onSuccess = () => {
    messageApi.open({
        type: 'success',
        content: 'อัปเดตข้อมูลสาขาสำเร็จ',
    });
}

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [notEmptyBranch, setNotEmptyBranch] = useState(true) 
  const [service, setService] = useState([])
  const [listOwner, setListOwner] = useState([])
  const [openHour, setOpenHour] = useState('')
  const [closeHour, setCloseHour] = useState('')

  const [openAddModal, setOpenAddModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openCustomBusinessHour, setOpenCustomBusinessHour] = useState(true)

  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const date = new Date()
  const lastmonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)

  const [bannerUrl, setBannerUrl] = useState('')
  const [bannerFile, setBannerFile] = useState([])

  const columns = [
    {
        title: '',
        width: 100,
        dataIndex: 'branchImage',
        key: 'branchImage',
        fixed: 'left',
        render: (text, record) => (
          <div>
            <Image
                width={100}
                src={text}
            />
          </div>
        )    
    },
    {
      title: 'ชื่อสาขา',
      width: 100,
      dataIndex: 'branchName',
      key: 'branchName',
      fixed: 'left',
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {<a href={'/saleReportBranch/'+record.branchCode} >{text}</a>}
        </div>
      )    
    },
    {
      title: 'ที่ตั้ง',
      width: 150,
      dataIndex: 'branchAddress',
      key: 'branchAddress',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },
    {
      title: 'จำนวนเครื่อง',
      width: 50,
      dataIndex: 'branchMachineCount',
      key: 'branchMachineCount',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },
    {
      title: 'เจ้าของ',
      width: 50,
      dataIndex: 'branchOwner',
      key: 'branchOwner',
      render: (text, record) => (
        <div>
          {text}
        </div>
      ),
    },
    {
      title: 'สถานะ',
      width: 50,
      dataIndex: 'branchStatus',
      key: 'branchStatus',
      render: (text, record) => (
        <div>
          {text === 'active' ? <b style={{color:'green'}}>เปิดบริการ</b> : <b style={{color:'red'}}>ปิดบริการ</b>}
        </div>
      ),
    },
    {
      title: 'จัดการ',
      width: 50,
      dataIndex: 'id',
      key: 'action',
      render: (text, record) => (
        <div>
            <Button type='primary' onClick={()=> showEditBranch(record.id, record)}>แก้ไข</Button>
        </div>
      ),
    }
  ]

  const showAddBranch = async function(){
    setBannerFile([])
    setOpenAddModal(true)
  }

  const addBranch = async function (values){

    if(openHour === ''){setOpenHour('00:00');}
    if(closeHour === ''){setCloseHour('23:59');}

    const formData = new FormData();

    if(bannerFile.length !== 0){ formData.append('branchImage', bannerFile[0]) }

    formData.append('branchName',values.branchName)
    formData.append('branchCode',values.branchCode)
    formData.append('branchAddress',values.branchAddress)
    formData.append('businessHours',values.businessHours)
    formData.append('openHour',openHour)
    formData.append('closeHour', closeHour)
    formData.append('branchService', values.branchService)
    formData.append('branchOwner',values.branchOwner)
    formData.append('branchStatus',values.branchStatus)

    try {
      const response = await axios.post(`${API_URL}addBranch`,formData , {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'multipart/form-data'
          }
      })
      if (response.status === 200) {
          fetchData()
          onSuccess();
      }
      setBannerFile([])
  } catch (error) {
      console.log(error);
  }
  }

  const showEditBranch = async function (branchId, record) {

    formEdit.setFieldsValue({
      branchId: record.id,
      branchName: record.branchName,
      branchAddress: record.branchAddress,
      businessHours: record.businessHours,
      branchService: record.branchService,
      customBusinessHour: [dayjs(record.openingHours,'HH:mm'),dayjs(record.closingHours,'HH:mm')],
      branchStatus: record.branchStatus
    })

    setBannerUrl(record.branchImage)

    setOpenHour(record.openingHours)
    setCloseHour(record.closingHours)

    if(record.businessHours === 'custom'){
      setOpenCustomBusinessHour(false)
    }

    setOpenEditModal(true)
  }

  const editBranch = async function (values){

    if(openHour === ''){setOpenHour('00:00');}
    if(closeHour === ''){setCloseHour('23:59');}

    const formData = new FormData();
    if(bannerFile.length !== 0){ formData.append('branchImage', bannerFile[0]) }

    formData.append('branchId',values.branchId)
    formData.append('branchName',values.branchName)
    formData.append('branchAddress',values.branchAddress)
    formData.append('businessHours',values.businessHours)
    formData.append('openHour',openHour)
    formData.append('closeHour',closeHour)
    formData.append('branchService',values.branchService)
    formData.append('branchStatus',values.branchStatus)

    try {
      const response = await axios.post(`${API_URL}editBranch`,formData , {
          headers: {
              'Authorization': `bearer ${token}`,
              'Content-Type': 'multipart/form-data'
          }
      })
      if (response.status === 200) {
          fetchData()
          onSuccess();
      }
  } catch (error) {
      console.log(error);
  }
  }

  const openCustomBusinessHourFunction = async function(value){
    if(value === 'custom'){
      setOpenCustomBusinessHour(false)
    }else{
      setOpenCustomBusinessHour(true) 
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}getListBranchDetail`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      if(response.data.length === 0){
        setNotEmptyBranch(false)
      }

      const adjustedData = response.data.map((branch) => ({
        ...branch,
        key: branch.branchCode,
      }));

      setData(adjustedData)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getService = async () =>{
    try {
      const response = await axios.get(`${API_URL}getBranchService`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      setService(response.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getListOwner = async () => {
    try {
      const response = await axios.get(`${API_URL}getListOwner`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })

      setListOwner(response.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const setBusinessHour = async (time) => {
    setOpenHour(dayjs(time[0]).format('HH:mm'));
    setCloseHour(dayjs(time[1]).format('HH:mm'));
  }

  const props1 = {
    multiple: false,
    onRemove: file => {
        setBannerFile([]);
    },
    beforeUpload: file => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        message.error(`รองรับไฟล์ภาพ PNG และ JPG`);
        return false;
      }
      setBannerFile(prev => [file]);
      return false;
    },
    fileList: bannerFile,
  };



  useEffect(() => {
    if(data.length === 0 && notEmptyBranch){
        fetchData()
        getService()
        getListOwner()
    }
  }, [data])

  return (
    <div className="fetch-event-table">
    <Row>
      <Col span={22}></Col>
      <Col span={2}>{ user && user.role === 'admin' ? <Button type='primary' onClick={showAddBranch}>เพิ่มสาขา</Button> : '' }</Col>
    </Row>
    <Row>
      <Col span={24}>
      {contextHolder}
      {
        <Modal
        title="เพิ่มสาขา"
        centered
        open={openAddModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            formAdd
                .validateFields()
                .then((values) => {
                    addBranch(values)
                    formAdd.resetFields();
                    setOpenAddModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
          formAdd.resetFields();
            setOpenAddModal(false);
            setOpenCustomBusinessHour(true)
        }}
        width={400}
    >
        <Form
            form={formAdd}
            layout="vertical"
        >
            <Form.Item name="branchName" label="ชื่อสาขา"
                rules={[ {
                        required: true,
                        message: 'กรุณากรอกชื่อสาขา',
                }, ]}>
                <Input type='text'/>
            </Form.Item>

            <Form.Item name="branchCode" label="รหัสสาขา"
                rules={[ {
                        required: true,
                        message: 'กรุณากรอกรหัสสาขา',
                }, ]}>
                <Input type='text'/>
            </Form.Item>

            <Form.Item name="bannerUrl" label="ลิงค์ภาพสาขา">
                <Upload {...props1}>
                    <Button icon={<UploadOutlined />}>เลือกภาพ</Button>
                </Upload>
              </Form.Item>

            <Form.Item name="branchOwner" label="เจ้าของ" rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกระบุเจ้าของสาขา',
                    },
                ]}>
                <Select>
                    {listOwner.map(ownerItem => (
                        <Select.Option key={ownerItem.id} value={ownerItem.id} label={ownerItem.name}>{ownerItem.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="branchAddress" label="ที่อยู่"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกที่อยู่',
                    },
                ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
            </Form.Item>
            <Form.Item name="businessHours" label="เวลาเปิดบริการ"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกเวลาเปิดบริการ',
                    },
                ]}>
                        <Select onChange={openCustomBusinessHourFunction}>
                                <Select.Option key={'24'} value={'24'} label={"24 ชั่วโมง"}>24 ชั่วโมง</Select.Option>
                                <Select.Option key={'custom'} value={'custom'} label={"กำหนดเอง"}>กำหนดเอง</Select.Option>
                        </Select>
            </Form.Item>

            <Form.Item name="customBusinessHour" label="กำหนดเวลาทำการ" hidden={openCustomBusinessHour} >
                        <TimePicker.RangePicker format={'HH:mm'} onChange={setBusinessHour}/>
            </Form.Item>
            <Form.Item name="branchService" label="บริการสาขา" >
                <Select
                  mode='multiple'
                  allowClear
                  optionFilterProp="label"
                >
                  
                    {service.map(serviceItem => (
                        <Select.Option key={serviceItem.id} value={serviceItem.id} label={serviceItem.name}>{serviceItem.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="branchStatus" label="สถานะ" rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกระบุสถานะ',
                    },
                ]}>
                <Select>
                        <Select.Option key={'active'} value={'active'} label={'เปิดบริการ'}>เปิดบริการ</Select.Option>
                        <Select.Option key={'deactive'} value={'deactive'} label={'ปิดบริการ'}>ปิดบริการ</Select.Option>
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
      {
        <Modal
        title="แก้ไขสาขา"
        centered
        open={openEditModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        onOk={() => {
            formEdit
                .validateFields()
                .then((values) => {
                    editBranch(values)
                    formEdit.resetFields();
                    setOpenEditModal(false);
                })
                .catch((errorInfo) => {
                    console.log('Failed:', errorInfo);
                });
        }}
        onCancel={() => {
          formEdit.resetFields();
            setOpenEditModal(false);
            setOpenCustomBusinessHour(true) 

        }}
        width={400}
    >
        <Form
            form={formEdit}
            layout="vertical"
        >
            <Form.Item name="branchId" hidden={true}>
                <Input type='hidden'/>
            </Form.Item>
            <Form.Item name="branchName" label="ชื่อสาขา"
                rules={[ {
                        required: true,
                        message: 'กรุณากรอกชื่อสาขา',
                }, ]}>
                <Input type='text'/>
            </Form.Item>
            <Form.Item name="branchAddress" label="ที่อยู่"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกที่อยู่',
                    },
                ]}>
                <TextArea autoSize={{ minRows: 2, maxRows: 6 }}/>
            </Form.Item>
            <Form.Item name="bannerUrl" label="ภาพสาขา">
                <Upload {...props1}>
                    { bannerUrl ? <img src={bannerUrl} alt="logo" style={{ width: '100%', cursor:'pointer' }} /> : ''}
                    { bannerUrl ? <Button icon={<UploadOutlined />}>เปลี่ยนภาพ</Button> : <Button icon={<UploadOutlined />}>เลือกภาพ</Button>}
                    
                </Upload>

              </Form.Item>
            <Form.Item name="businessHours" label="เวลาเปิดบริการ"
                rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกเวลาเปิดบริการ',
                    },
                ]}>
                        <Select onChange={openCustomBusinessHourFunction}>
                                <Select.Option key={'24'} value={'24'} label={"24 ชั่วโมง"}>24 ชั่วโมง</Select.Option>
                                <Select.Option key={'custom'} value={'custom'} label={"กำหนดเอง"}>กำหนดเอง</Select.Option>
                        </Select>
            </Form.Item>

            <Form.Item name="customBusinessHour" label="กำหนดเวลาทำการ" hidden={openCustomBusinessHour} >
                        <TimePicker.RangePicker format={'HH:mm'} onChange={setBusinessHour}/>
            </Form.Item>
            <Form.Item name="branchService" label="บริการสาขา" >
                <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="label">
                    {service.map(serviceItem => (
                        <Select.Option key={serviceItem.id} value={serviceItem.id} label={serviceItem.name}>{serviceItem.name}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item name="branchStatus" label="สถานะ" rules={[
                    {
                        required: true,
                        message: 'กรุณากรอกระบุสถานะ',
                    },
                ]}>
                <Select>
                        <Select.Option key={'active'} value={'active'} label={'เปิดบริการ'}>เปิดบริการ</Select.Option>
                        <Select.Option key={'deactive'} value={'deactive'} label={'ปิดบริการ'}>ปิดบริการ</Select.Option>
                </Select>
            </Form.Item>
        </Form>
        </Modal>
      }
      {notEmptyBranch ? 
      data && data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{
            x: 400,
          }}
          rowKey="branchCode"
        />
      ) : (
        <p>Loading</p>
      )
      :<p>ไม่พบสาขา</p>}
    </Col></Row>
    </div>

  )
}

export default ListTransaction