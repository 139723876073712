import React, { useContext, useEffect, useState } from "react";

import MenuSide from "../components/menuSide";
import FetchCustomer from "../components/fetchCustomer"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import {UserOutlined} from "@ant-design/icons"
import { AppContext } from '../App';

const { Title } = Typography;
const { Content } = Layout;


const User = () => {

    const { user } = useContext(AppContext);
    if(user){ 
      if(user.role !== 'admin'){      
        window.location.href = "/home"; 
      }
    }
  
    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={1}></Col>
                <Col span={22}><Title level={3}><UserOutlined /> ลูกค้า</Title></Col>
                <Col span={1}></Col>

            </Row>
            <Row>
                <Col span={1}></Col>
                <Col span={22}><FetchCustomer /></Col>
                <Col span={1}></Col>

            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default User;
  