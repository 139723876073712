import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography} from "antd";
import { TransactionOutlined } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import ListTransaction from "../components/listTransaction"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const Transaction = () => {

  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }
  
    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <Title level={3} ><TransactionOutlined /> รายการชำระเงิน</Title>
            <ListTransaction />
          </Content>
          
      </Layout>
    )
  };
  
  export default Transaction;
  