import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography, Row, Col, Button} from "antd";
import { DatabaseOutlined } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import ListMachineControl from "../components/listMachineControl"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const MachineControlHistory = () => {

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <Row>
              <Col span={1} ></Col>
              <Col span={22} ><Title level={3} ><DatabaseOutlined /> ประวัติการควบคุมเครื่องซักผ้า</Title></Col>
              <Col span={1} ></Col>
            </Row>
            <br />
            <Row>
              <Col span={1} ></Col>
              <Col span={22} ><ListMachineControl /></Col>
              <Col span={1} ></Col>
            </Row>
            
          </Content>
          
      </Layout>
    )
  };
  
  export default MachineControlHistory;
  