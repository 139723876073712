import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Input, Space, Button, message, Tag, Layout, Row, Col, Typography, Form, Modal, DatePicker, TimePicker, Select, Spin} from 'antd'
import { SearchOutlined,ExclamationCircleOutlined, TagsOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import { AppContext } from '../App';
import dayjs from 'dayjs'

const { Header, Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;


const ListPromotionBranch = () => {
  const { user } = useContext(AppContext);

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('Authentication');

  const [data, setData] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: Math.floor(window.screen.height/125),
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [onCheckTransaction, setOnCheckTransaction] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModalQrcode, setOpenModalQrcode] = useState(false)

  const [modalContent, setModalContent] = useState('')
  const [promotionSelected, setPromotionSelected] = useState('');
  const [messageConfirmDelete, setMessageConfirmDelete] = useState('ยืนยันการลบคูปอง');

  const [allData,setAllData] = useState([])
  const [activeData,setActiveData] = useState([])
  const [deactiveData,setDeactiveData] = useState([])

  const [allTotal,setallTotal] = useState(0)
  const [activeTotal,setActiveTotal] = useState(0)
  const [deactiveTotal,setDeactiveTotal] = useState(0)

  const [typeAll, setTypeAll] = useState('primary')
  const [typeActive, setTypeActive] = useState('')
  const [typeDeactive, setTypeDeactive] = useState('')



  const [form] = Form.useForm();

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
          style={{
          padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
      >
          <Input
          ref={searchInput}
          placeholder={`ค้นหาคูปอง`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
              marginBottom: 8,
              display: 'block',
          }}
          />
          <Space>
          <Button
              type="primary"
              icon={<SearchOutlined />}
              size="small"
              style={{
              width: 120,
              }}
              onClick={() => {
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
          >
              Search
          </Button>
          <Button
              onClick={() => {
                  clearFilters
                  handleReset(clearFilters)
                  confirm({
                      closeDropdown: false,
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
              }}
              size="small"
              style={{
              width: 50,
              }}
          >
              Reset
          </Button>
          </Space>
      </div>
      ),
      filterIcon: (filtered) => (
      <SearchOutlined
          style={{
          color: filtered ? '#000000' : undefined,
          }}
      />
      ),
      onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
      if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
      }
      },
  });
  
  const columns = [
    {
      title: 'ชื่อโปรโมชั่น',
      width: 200,
      dataIndex: 'name',
      key: 'name',
      // fixed: 'left',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        <div style={{ fontWeight: 600, color: "#3a9bdc" }}>
          {text}
        </div>
      )    
    },
    {
        title: 'สาขาที่เข้าร่วม',
        width: 80,
        dataIndex: 'listBranchName',
        key: 'listBranchName',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ? text.toString() : ''}
          </div>
        ),
    },
    {
        title: 'ช่วงวันที่โปรโมชั่น',
        width: 150,
        dataIndex: 'promotionStart',
        key: 'promotionStart',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ?  moment(record.promotionStart).format('DD/MM/YYYY') + " - " +  moment(record.promotionEnd).format('DD/MM/YYYY') : ''}
          </div>
        ),
    },
    {
        title: 'ช่วงเวลาโปรโมชั่น',
        width: 80,
        dataIndex: 'promotionStart',
        key: 'promotionStart',
        // fixed: 'left',
        render: (text, record) => (
          <div>
            {text ?  record.promotionTimeStart + " - " +  record.promotionTimeEnd : ''}
          </div>
        ),
    },
    {
        title: 'สถานะ',
        width: 90,
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <div>
             {status === 'active' ? <Tag color='green'>เปิดใช้งาน</Tag> : (status === 'deactive' ? <Tag color='orange'>ปิดใช้งาน</Tag> : '')}
          </div>
        ),
    },

    {
      title: 'รายละเอียด',
      key: 'action',
      // fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
             { <div><Button type='primary' onClick={()=>showQrcode(record)}>รายละเอียด</Button></div> }
        </div>
      ),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}listPromotionBranch`, {
        headers: {
          'Authorization': `bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
          page: pagination.current,
          pageSize: pagination.pageSize,
        },
      })

      const adjustedData = response.data.map((promotion) => ({
        ...promotion,
        key: promotion.id,
      }));

      setData(adjustedData)

      const activeData = adjustedData.filter((item) => item.status === 'active')
      const deactiveData = adjustedData.filter((item) => item.status === 'deactive')

      setAllData(adjustedData)
      setActiveData(activeData)
      setDeactiveData(deactiveData)


      setallTotal(adjustedData.length)
      setActiveTotal(activeData.length)
      setDeactiveTotal(deactiveData.length)


      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          total: response.total,
          pageSize: Math.floor(window.screen.height / 90),
        };
      });

    } catch (error) {
      console.log('error', error)
    }
  }

  const showQrcode = async (record) => {

        setOpenModalQrcode(true)
        
        const machineType = record.machineType
        const machineSize = record.machineSize
        const paymentType = record.paymentType
        const promotionType = record.promotionType
        const discountType = record.discountType
        const discountAmount = record.discountAmount
        const listBranchName = record.listBranchName

        var stringPromotionDays = (record.promotionDays).toString();
        if(stringPromotionDays.includes('all')){ stringPromotionDays = 'ทุกวัน'}
        else{
            stringPromotionDays = stringPromotionDays.replace("Mon", "จันทร์");
            stringPromotionDays = stringPromotionDays.replace("Tue", "อังคาร");
            stringPromotionDays = stringPromotionDays.replace("Wed", "พุธ");
            stringPromotionDays = stringPromotionDays.replace("Thu", "พฤหัสบดี");
            stringPromotionDays = stringPromotionDays.replace("Fri", "ศุกร์");
            stringPromotionDays = stringPromotionDays.replace("Sat", "เสาร์");
            stringPromotionDays = stringPromotionDays.replace("Sun", "อาทิตย์");
         }

        setModalContent(
          <div className='ModalContentLeft'>
          <br/>
          <p><b>ชื่อโปรโมชั่น: </b> {record.name}</p>
          <p><b>ประเภทโปรโมชั่น: </b> {promotionType ? (promotionType == 'discount' ? 'ส่วนลด' : (promotionType == 'earnpoint' ? 'ได้รับคะแนน' : (promotionType == 'cashback' ? 'เงินคืน' : promotionType)) ) : ''}</p>
          <p><b>ประเภทส่วนลด: </b> {discountType ? (discountType == 'fixed' ? 'คงที่' : (discountType == 'percent' ? 'เปอร์เซ็นต์' : (discountType == 'free' ? 'ฟรี' : discountType)) ) : ''}</p>
          <p><b>จำนวน: </b> {discountAmount ? discountAmount : ''} { discountType == 'percent' ? " %" : (promotionType == 'earnpoint' ? 'เท่า' : 'บาท') }</p>
          <br/>
          <p><b>ช่วงวันที่โปรโมชั่น: </b> {record.promotionStart ?  moment(record.promotionStart).format('DD/MM/YYYY') + " - " +  moment(record.promotionEnd).format('DD/MM/YYYY') : ''}</p>
          <p><b>ช่วงเวลาโปรโมชั่น: </b> {record.promotionTimeStart ?  record.promotionTimeStart + " - " +  record.promotionTimeEnd : ''}</p>
          <p><b>วันที่เข้าร่วม (รายสัปดาห์): </b> {stringPromotionDays ?  stringPromotionDays : ''}</p>
          <br/>
          <p><b>สาขาที่เข้าร่วม: </b> {listBranchName ? listBranchName.toString() : ''}</p>
          <br/>
          <p><b>ประเภทเครื่อง: </b> {machineType ? (machineType == 'all' ? "ทุกประเภท" : (machineType == 'washer' ? 'เครื่องซัก' : (machineType == 'dryer' ? 'เครื่องอบ' : '' ) ) ) : ''}</p>
          <p><b>ขนาดเครื่อง(กก.): </b> {machineSize == 'all' ? 'ทุกขนาด' : machineSize}</p>
          <br/>
          <p><b>ประเภทการชำระเงิน: </b> { paymentType ? (paymentType == 'all' ? 'ทุกประเภท' : (paymentType == 'wallet' ? 'กระเป๋าเงิน' : (paymentType == 'payment' ? record.paymentCode : paymentType))) : ''}</p>
      
      
      </div>
        )
    }
        

  const handleCancelQrcode = () => {
    setOpenModalQrcode(false)
  }


  const setDisplayData = function (status){
    if(status === 'all'){
      setData(allData)

      setTypeAll('primary')
      setTypeActive('')
      setTypeDeactive('')
    }else if(status === 'active'){
        setData(activeData)

        setTypeAll('')
        setTypeActive('primary')
        setTypeDeactive('')
    }else if(status === 'deactive'){
        setData(deactiveData)

        setTypeAll('')
        setTypeActive('')
        setTypeDeactive('primary')
    }
  }


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="fetch-event-table">
      {contextHolder}
      {
        <Modal
        title='รายละเอียดโปรโมชั่น'
        centered
        open={openModalQrcode}
        footer={null}
        width={700}
        onCancel={handleCancelQrcode}
        >
        {modalContent}
        </Modal>
      }
      <Row>

            <Col span={24} >
                <Title level={3} ><TagsOutlined /> โปรโมชั่น</Title>
            </Col>
        </Row>
        <Row>
            <Col span={24} >
            สถานะโปรโมชั่น : <Button type={typeAll} onClick={()=> setDisplayData('all')}>ทั้งหมด ({allTotal})</Button>  &nbsp;
                        <Button type={typeActive} onClick={()=> setDisplayData('active')}>ใช้งานอยู่ ({activeTotal})</Button> &nbsp;
                        <Button type={typeDeactive} onClick={()=> setDisplayData('deactive')}>ปิดใช้งาน ({deactiveTotal})</Button>  &nbsp;


            </Col>
        </Row>
        <Row>
            <Col span={24} >
                {data && data.length > 0 ? (
                    <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    onChange={(newPagination) => setPagination(newPagination)}
                    scroll={{
                        x: 400,
                    }}
                    rowKey="id"
                    />
                ) : (
                    data.length == 0 ? '' : <Spin />
                )}
            </Col>
        </Row>
    </div>
  )
}

export default ListPromotionBranch