import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography,Row, Col} from "antd";

import MenuSide from "../components/menuSide";
import ListCoupon from "../components/ListCoupon"
import { AppContext } from '../App';


const { Header, Content } = Layout;
const { Title } = Typography;

const Coupon = () => {

  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">

            <Row>
                <Col span={1} />
                <Col span={22} >
                    <ListCoupon />
                </Col>
                <Col span={1} />
            </Row>
            



          </Content>
          
      </Layout>
    )
  };
  
  export default Coupon;
  