import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";

import MenuSide from "../components/menuSide";
import { Layout, theme, Tabs } from "antd";

import { AppContext } from '../App';

const { Content } = Layout;

const Dashboard = () => {

  const token = localStorage.getItem('Authentication');
  const { logout, user } = useContext(AppContext);

  const [saleToday, setsaleToday] = useState(0)
  const [saleThisMonth, setsaleThisMonth] = useState(0)
  const [salePrevMonth, setsalePrevMonth] = useState(0)

  const date = new Date()
  const lastmonth = new Date(date.getFullYear(), date.getMonth() - 1, 1)

  const thisMonthName = date.toLocaleDateString('th-TH', { month: 'long',})
  const lastMonthName = lastmonth.toLocaleDateString('th-TH', { month: 'long',})

  useEffect( () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL+'saleDashboard/',
      headers: {
        'Authorization': `bearer ${token}`,
        'Content-Type': 'application/json'
      },
    };
    
    axios.request(config)
    .then((response) => {
      setsaleToday(response.data.totalSaleThisDay)
      setsaleThisMonth(response.data.totalSaleThisMonth)
      setsalePrevMonth(response.data.totalSalePrevMonth)

    })
    .catch((error) => {
      console.log(error);
    });

  })

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <div className="dashboardOrigamiContent">
              <img src="origami-icon.png" alt="origami-icon" width={250} height={250}></img>
              <div className="headerDashboard"> รายได้ Origami Application สาขา ลาดพร้าว 101  <br/> ณ วันที่ {dayjs().format('DD/MM/YYYY HH:mm:ss')}</div>
            
              <h2> รายได้วันนี้: {saleToday.toLocaleString()} บาท</h2>
              <h2> {thisMonthName}: {saleThisMonth.toLocaleString()} บาท</h2>
              <h2> {lastMonthName}: {salePrevMonth.toLocaleString()} บาท</h2>
          </div>
          </Content>
          
      </Layout>
    )
  };
  
  export default Dashboard;
  