import React, { useContext, useEffect, useState } from "react";

import MenuSide from "../components/menuSide";
import AddFanshine from "../components/addFanshine"
import ListFanshine from "../components/listFanshine"
import { Layout, theme, Tabs, Typography, Row, Col } from "antd";
import { DeploymentUnitOutlined } from '@ant-design/icons';
import { AppContext } from '../App';

const { Title } = Typography;
const { Content } = Layout;


const Fanshine = () => {

  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }
  
    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className='contentSection'>
          <Row>
                <Col span={1} />
                <Col span={11}><Title level={3}><DeploymentUnitOutlined /> แฟรนไชส์</Title></Col>
                <Col span={11} className="rigthContent"><AddFanshine /></Col>
                <Col span={1} />
            </Row>
            <Row>
                <Col span={1} />
                <Col span={22}><ListFanshine /></Col>
                <Col span={1} />
            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default Fanshine;
  