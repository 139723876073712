import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../App';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Flex, Form, Input, Layout, message } from 'antd';
import origamiIcon from '../images/origami-icon.png';

const Login = () => {

    const [onloading, setOnloading] = useState(false);
    const { login, user, handleRedirect } = useContext(AppContext);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(()=>{
        if(localStorage.getItem('Authentication') && user){
            handleRedirect(user.role);
        }
    }, [ user ]);

    const onError = () => {
        messageApi.open({
            type: 'error',
            content: 'Incorrect Username or Password',
        });
    }

    const onFinish = async (values) => {
        setOnloading(true)
        try {
            const result = await login(values.username, values.password);

            if (result != true) {
                onError();
            }
        } catch (error) {
            console.error('Error :', error);
        }
        setOnloading(false)

    };

    return (
        <Layout style={{ minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            {contextHolder}
            <Card bordered={false}>
                <Flex justify="center" align="center" vertical>
                    <img src={origamiIcon} alt="Logo" className="origamiIcon"/>

                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        style={{ justifyContent: "center" }}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"

                            />
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }} loading={onloading}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </Flex>
            </Card>

        </Layout>
    );
}

export default Login;