import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { Table, Image, Input, Space, Button, message, Tag, Empty, Spin, Form, Select, Card, Modal, DatePicker} from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import * as XLSX from 'xlsx'

const { RangePicker } = DatePicker;
const listYear = [ 2023, 2024];
const months_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];

import { AppContext } from '../App';

const AccountReportDownload = () => {

    const { user } = useContext(AppContext);

    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('Authentication');
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [branch, setBranch] = useState([])


    const [displayOmise,setDisplayOmise] = useState(false)
    const [displayWallet,setDisplayWallet] = useState(false)
    const [displayTransaction,setDisplayTransaction] = useState(false)
    const [displayFanshine,setDisplayFanshine] = useState(false)

    const [fanshineBranch, setFanshineBranch] = useState("");
    const [fanshineYear, setFanshineYear] = useState("");
    const [fanshineMonth, setFanshineMonth] = useState("");


    const [omiseStartDate,setOmiseStartDate] = useState("")
    const [omiseEndDate,setOmiseEndDate] = useState("")

    const [walletStartDate,setWalletStartDate] = useState("")
    const [walletEndDate,setWalletEndDate] = useState("")

    const [transactionStartDate,setTransactionStartDate] = useState("")
    const [transactionEndDate,setTransactionEndDate] = useState("")

    const [loadingOmise,setLoadingOmise] = useState(false)
    const [loadingWallet,setLoadingWallet] = useState(false)
    const [loadingTransaction,setLoadingTransaction] = useState(false)
    const [loadingFanshine,setLoadingFanshine] = useState(false)
    
    


    const showOmisePickDate = () => {
        setDisplayOmise(true)
    }

    const showWalletPickDate = () => {
        setDisplayWallet(true)
    }

    const showTransactionPickDate = () => {
        setDisplayTransaction(true)
    }

    const showFanshinePickDate = () => {
        setDisplayFanshine(true)
    }

    const handleOmiseCancel = () => {
        setDisplayOmise(false)
    }

    const handleWalletCancel = () => {
        setDisplayWallet(false)
    }

    const handleTransactionCancel = () => {
        setDisplayTransaction(false)
    }

    const handleFanshineCancel = () => {
        setDisplayFanshine(false)
    }

    const handleOmiseOk = async () => {
        setLoadingOmise(true)
        if(omiseStartDate && omiseEndDate){
            setDisplayOmise(false)
            
            const omiseDataDownload = await fetchReportOmise(omiseStartDate,omiseEndDate)

            
            if(omiseDataDownload.length === 0){
                messageApi.open({
                    type: 'success',
                    content: 'ไม่พบข้อมูล',
                });
            }else{
                var topupRecord, checkoutRecord;
                const topupData = []
                const checkoutData = []

                const headerTopup = {
                    orderId: "orderId",
                    chargeId: "chargeId (omise)",
                    paymentDate: "วันที่ชำระสำเร็จ",
                    paymentType: "ประเภทการชำระ",
                    paymentName: "ช่องทางการชำระ",
                    amountBeforeTax: "จำนวนเงินก่อนภาษี",
                    tax: "ภาษี",
                    amount: "จำนวนเงิน",
                    description: "คำอธิบาย",
                    walletAddress: "walletAddress",
                    customerId: "customerId",
                    phone: "โทรศัพท์",
                    status: "สถานะ"
                }
                const headercheckOut = {
                    orderId: "orderId",
                    chargeId: "chargeId (omise)",
                    paymentDate: "วันที่ชำระสำเร็จ",
                    paymentType: "ประเภทการชำระ",
                    paymentName: "ช่องทางการชำระ",
                    amountBeforeTax: "จำนวนเงินก่อนภาษี",
                    tax: "ภาษี",
                    amount: "จำนวนเงิน",
                    description: "คำอธิบาย",
                    customerId: "customerId",
                    phone: "โทรศัพท์",
                    status: "สถานะ"
                }

                topupData.push(headerTopup)
                checkoutData.push(headercheckOut)

                for(let element of omiseDataDownload){
                    if(element.paymentType === 'topup'){
                        topupRecord = {
                            orderId: element.orderId,
                            chargeId: element.chargeId,
                            paymentDate: moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                            paymentType: "เติมเงิน",
                            paymentName: element.paymentName,
                            amountBeforeTax: ((element.amount * 100)/107).toFixed(2),
                            tax: ((element.amount * 7)/107).toFixed(2),
                            amount: element.amount,
                            description: "เติมเงิน "+ element.amount + " บาท",
                            walletAddress: element.walletAddress,
                            customerId: element.customerId,
                            phone: element.customer.phone,
                            status: element.status
                        }
                        topupData.push(topupRecord)
                    }else if(element.paymentType === 'checkout'){
                        checkoutRecord = {
                            orderId: element.orderId,
                            chargeId: element.chargeId,
                            paymentDate: moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                            paymentType: "ชำระเงิน",
                            paymentName: element.paymentName,
                            amountBeforeTax: ((element.amount * 100)/107).toFixed(2),
                            tax: ((element.amount * 7)/107).toFixed(2),
                            amount: element.amount,
                            description: "ชำระเงิน "+ element.amount + " บาท",
                            customerId: element.customerId,
                            phone: element.customer.phone,
                            status: element.status
                        }
                        checkoutData.push(checkoutRecord)

                    }
                }

                // add width
                var wscols = []
                for(let indexWidth = 0 ; indexWidth < 12 ; indexWidth++){
                wscols.push({wch:20})
                }

                const workbook = XLSX.utils.book_new()
                const worksheetTopup = XLSX.utils.json_to_sheet(topupData, {skipHeader:true})
                const worksheetCheckout = XLSX.utils.json_to_sheet(checkoutData, {skipHeader:true})
                worksheetTopup['!cols'] = wscols;
                worksheetCheckout['!cols'] = wscols;

            
                XLSX.utils.book_append_sheet(workbook, worksheetTopup, 'รายงานเติมเงิน')
                XLSX.utils.book_append_sheet(workbook, worksheetCheckout, 'รายงานชำระเงิน')

                XLSX.writeFile(workbook, 'รายงาน Omise '+omiseStartDate+ '_' + omiseEndDate+'.xlsx')

            }
        }
        setLoadingOmise(false)

    }

    const handleWalletOk = async () => {

        setLoadingWallet(true)


        if(walletStartDate && walletEndDate){
            setDisplayWallet(false)
            
            const walletDataDownload = await fetchReportWallet(walletStartDate,walletEndDate)

            const reportWallet = walletDataDownload.reportWallet
            const reportSummary = walletDataDownload.reportSummary
            const reportTopup = walletDataDownload.reportTopup
            const reportCheckout = walletDataDownload.reportCheckout
            const reportCashback = walletDataDownload.reportCashback


            var walletRecord, summaryRecord, topupRecord, checkoutRecord, cashbackRecord;
            const walletData = []
            const summaryData = []
            const topupData = []
            const checkoutData = []
            const cashbackData = []

            const headerWallet = {
                walletId: "รหัสกระเป๋าเงิน",
                customerId: "รหัสลูกค้า",
                customerPhone: "เบอร์โทรลูกค้า",
                walletBalance: "ยอดเงินคงเหลือ",
            }

            const headerSummary = {
                walletId: "รหัสกระเป๋าเงิน",
                customerId: "รหัสลูกค้า",
                customerPhone: "เบอร์โทรลูกค้า",
                summaryTopupBeforeTax: "ยอดเติมเงินก่อนภาษี",
                summaryTopupTax: "ภาษียอดเติมเงิน",
                summaryTopup: "จำนวนเงินที่เติมทั้งหมด",
                summaryCheckout: "จำนวนเงินที่ชำระทั้งหมด",
                summaryCashback: "จำนวนเงินคืนทั้งหมด",
            }

            const headerTopup = {
                orderId:"orderId",
                walletId:"รหัสกระเป๋าเงิน",
                transactionType:"ประเภท",
                amountBeforeTax:"ยอดเงินก่อนภาษี",
                tax:"ภาษี",
                transactionAmount:"ยอดเงิน",
                transactionDetail:"รายละเอียด",
                createdAt:"วันที่ชำระ",
                status: "สถานะ"
            }

            const headercheckOut = {
                orderId:"orderId",
                walletId:"รหัสกระเป๋าเงิน",
                transactionType:"ประเภท",
                transactionAmount:"ยอดเงิน",
                transactionDetail:"รายละเอียด",                
                createdAt:"วันที่ชำระ",
                status: "สถานะ"
            }

            const headerCashback = {
                orderId:"orderId",
                walletId:"รหัสกระเป๋าเงิน",
                transactionType:"ประเภท",
                transactionAmount:"ยอดเงิน",
                transactionDetail:"รายละเอียด",                
                createdAt:"วันที่ชำระ",
                status: "สถานะ"
            }
               
            walletData.push(headerWallet)
            summaryData.push(headerSummary)
            topupData.push(headerTopup)
            checkoutData.push(headercheckOut)
            cashbackData.push(headerCashback)

                for(let element of reportWallet){

                    walletRecord = {
                        walletId: element.id,
                        customerId: element.customerId,
                        customerPhone: element.customer.phone,
                        walletBalance: element.walletBalance,
                    }
                    walletData.push(walletRecord)
                }

                for(let element of reportSummary){

                    summaryRecord = {
                        walletId: element.walletId,
                        customerId: element.customerId,
                        customerPhone: element.customerPhone,
                        summaryTopupBeforeTax: element.summaryTopup ? ((element.summaryTopup * 100)/107).toFixed(2) : 0,
                        summaryTopupTax: element.summaryTopup ? ((element.summaryTopup * 7)/107).toFixed(2) : 0,
                        summaryTopup: element.summaryTopup ? element.summaryTopup : 0,
                        summaryCheckout: element.summaryCheckout ? element.summaryCheckout : 0,
                        summaryCashback: element.summaryCashback ? element.summaryCashback : 0
                    }
                    summaryData.push(summaryRecord)
                

                }


                for(let element of reportTopup){

                    topupRecord = {
                        orderId: element.orderId,
                        walletId: element.walletId,
                        transactionType:"เติมเงิน",
                        amountBeforeTax: ((element.transactionAmount * 100)/107).toFixed(2),
                        tax: ((element.transactionAmount * 7)/107).toFixed(2),
                        transactionAmount: element.transactionAmount,
                        transactionDetail:"เติมเงิน "+element.transactionAmount+" บาท",
                        createdAt:moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                        status: "successful"
                    }
                    topupData.push(topupRecord)
                }

                for(let element of reportCheckout){

                    checkoutRecord = {
                        orderId: element.orderId,
                        walletId: element.walletId,
                        transactionType:"ชำระเงิน",
                        transactionAmount: element.transactionAmount,
                        transactionDetail:"ชำระเงิน "+element.transactionAmount+" บาท",
                        createdAt:moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                        status: "successful"
                    }
                    checkoutData.push(checkoutRecord)
                }


                for(let element of reportCashback){

                    cashbackRecord = {
                        orderId: element.orderId,
                        walletId: element.walletId,
                        transactionType:"เงินคืน",
                        transactionAmount: element.transactionAmount,
                        transactionDetail:"เงินคืน "+element.transactionAmount+" บาท",
                        createdAt:moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                        status: "successful"
                    }
                    cashbackData.push(cashbackRecord)
                }





                // add width
                var wscols = []
                for(let indexWidth = 0 ; indexWidth < 12 ; indexWidth++){
                wscols.push({wch:20})
                }

                const workbook = XLSX.utils.book_new()
                const worksheetWallet = XLSX.utils.json_to_sheet(walletData, {skipHeader:true})
                const worksheetSummary = XLSX.utils.json_to_sheet(summaryData, {skipHeader:true})
                const worksheetTopup = XLSX.utils.json_to_sheet(topupData, {skipHeader:true})
                const worksheetCheckout = XLSX.utils.json_to_sheet(checkoutData, {skipHeader:true})
                const worksheetCashback = XLSX.utils.json_to_sheet(cashbackData, {skipHeader:true})

                worksheetWallet['!cols'] = wscols;
                worksheetSummary['!cols'] = wscols;
                worksheetTopup['!cols'] = wscols;
                worksheetCheckout['!cols'] = wscols;
                worksheetCashback['!cols'] = wscols;

            
                XLSX.utils.book_append_sheet(workbook, worksheetWallet, 'ยอดเงินคงเหลือในกระเป๋า')
                XLSX.utils.book_append_sheet(workbook, worksheetSummary, 'สรุปยอดการใช้กระเป๋าเงิน')
                XLSX.utils.book_append_sheet(workbook, worksheetTopup, 'ประวัติการเติมเงิน')
                XLSX.utils.book_append_sheet(workbook, worksheetCheckout, 'ประวัติการชำระเงิน')
                XLSX.utils.book_append_sheet(workbook, worksheetCashback, 'ประวัติเงินคืน')

                XLSX.writeFile(workbook, 'รายงานการใช้กระเป๋าเงิน '+walletStartDate+ '_' + walletEndDate+'.xlsx')
        }
        setLoadingWallet(false)

    }

    const handleTransactionOk = async () => {

        setLoadingTransaction(true)

        if(transactionStartDate && transactionEndDate){
            setDisplayTransaction(false)
            
            const transactionDownloadData = await fetchReportTransaction(transactionStartDate,transactionEndDate)
            
            if(transactionDownloadData.length === 0){
                messageApi.open({
                    type: 'success',
                    content: 'ไม่พบข้อมูล',
                });
            }else{
                var laundryRecord;
                const laundryData = []

                const headerTopup = {
                    orderId: "orderId",
                    machineId: "machineId",
                    machineCode: "รหัสเครื่อง",
                    machineName: "ชื่อเครื่อง",
                    machineType: "ประเภทเครื่อง",
                    branchCode: "รหัสสาขา",
                    branchName: "ชื่อสาขา",
                    customerId: "รหัสลูกค้า",
                    customerPhone: "เบอร์โทร",
                    checkoutType: "วิธีการชำระ",
                    paymentName: "รายละเอียดการชำระ",
                    chargeId: "chargeId (omise)",
                    walletId: "รหัสกระเป๋าเงิน",
                    pointWalletId: "รหัสกระเป๋าคะแนน",
                    pointRedeem: "คะแนนที่แลก",
                    couponCode: "รหัสคูปอง",
                    couponValue: "มูลค่าคูปอง",
                    amount: "ยอดเงิน",
                    discount: "ส่วนลด",
                    totalAmount: "ยอดเงินทั้งสิ้น",
                    createdAt: "วันที่ชำระ",
                    machineTime: "เวลาเครื่องทำงาน",
                    status: "สถานะ"
                }
                

                laundryData.push(headerTopup)

                for(let element of transactionDownloadData){
                    
                    const laundryRecord = {
                        orderId: element.orderId,
                        machineId: element.machineId,
                        machineCode: element.machine.code,
                        machineName: element.machine.name,
                        machineType: element.machine.type === 'washer' ? 'เครื่องซัก' : ( element.machine.type === 'dryer' ? 'เครื่องอบ' : element.machine.type),
                        branchCode: element.machine.branch.code,
                        branchName: element.machine.branch.name,
                        customerId: element.customerId,
                        customerPhone: element.customer.phone,
                        checkoutType: element.checkoutType,
                        paymentName: element.paymentTransaction ? element.paymentTransaction.paymentName : '',
                        chargeId: element.paymentTransaction ? element.paymentTransaction.chargeId : '',
                        walletId: element.walletId,
                        pointWalletId: element.pointWalletId,
                        pointRedeem: element.pointRedeem,
                        couponCode: element.coupon ? element.coupon.code : '',
                        couponValue: element.couponValue ? element.couponValue : '',
                        amount: element.amount,
                        discount: element.discount,
                        totalAmount: element.totalAmount,
                        createdAt: moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                        machineTime: element.machineTime ? element.machineTime : '',
                        status: "SUCCESSFUL"
                    }

                    laundryData.push(laundryRecord)
                }

                // add width
                var wscols = []
                for(let indexWidth = 0 ; indexWidth < 23 ; indexWidth++){
                wscols.push({wch:20})
                }

                const workbook = XLSX.utils.book_new()
                const worksheet = XLSX.utils.json_to_sheet(laundryData, {skipHeader:true})
                worksheet['!cols'] = wscols;

            
                XLSX.utils.book_append_sheet(workbook, worksheet, 'ประวัติการใช้งานเครื่อง')

                XLSX.writeFile(workbook, 'รายงานประวัติการใช้งานเครื่อง '+transactionStartDate+ '_' + transactionEndDate+'.xlsx')


            }
        }
        setLoadingTransaction(false)

    }

    const handleFanshineOk = async () => {

        setLoadingFanshine(true)

        var branchName = '';
        branch.forEach(branchOne => {
            if(branchOne.branchId == fanshineBranch){
                branchName = branchOne.branchName; 
            }
        });

        if(fanshineBranch && fanshineYear && fanshineMonth){

            setDisplayFanshine(false)
            const fanshineDownloadData = await fetchReportFanshine(fanshineBranch, fanshineYear, fanshineMonth)

            const transactionResult = fanshineDownloadData.transactionResult
            const sqIncomeResult = fanshineDownloadData.sqIncomeResult
            const fanshineResult = fanshineDownloadData.fanshineResult

            var totalIncomeApp = 0;
            var totalIncomeCoin = 0;
            var fanshineFee =  parseInt(fanshineResult.fanshineFee)
        

            if(transactionResult.length === 0){
                messageApi.open({
                    type: 'success',
                    content: 'ไม่พบข้อมูล',
                });
            }else{
                var laundryRecord;
                const laundryData = []
                const sqIncomeData = []
                const summaryData = []

                const headerTopup = {
                    orderId: "orderId",
                    machineId: "machineId",
                    machineCode: "รหัสเครื่อง",
                    machineName: "ชื่อเครื่อง",
                    machineType: "ประเภทเครื่อง",
                    branchCode: "รหัสสาขา",
                    branchName: "ชื่อสาขา",
                    customerId: "รหัสลูกค้า",
                    customerPhone: "เบอร์โทร",
                    checkoutType: "วิธีการชำระ",
                    paymentName: "รายละเอียดการชำระ",
                    chargeId: "chargeId (omise)",
                    walletId: "รหัสกระเป๋าเงิน",
                    pointWalletId: "รหัสกระเป๋าคะแนน",
                    pointRedeem: "คะแนนที่แลก",
                    couponCode: "รหัสคูปอง",
                    couponValue: "มูลค่าคูปอง",
                    amount: "ยอดเงิน",
                    discount: "ส่วนลด",
                    totalAmount: "ยอดเงินทั้งสิ้น",
                    createdAt: "วันที่ชำระ",
                    machineTime: "เวลาเครื่องทำงาน",
                    status: "สถานะ"
                }

                const headerSqIncome = {
                    incomeDate: "วันที่",
                    incomeTotal: "รายได้จากเหรียญ"
                }


                laundryData.push(headerTopup)
                sqIncomeData.push(headerSqIncome)

                for(let element of transactionResult){
    
                    const laundryRecord = {
                        orderId: element.orderId,
                        machineId: element.machineId,
                        machineCode: element.machine.code,
                        machineName: element.machine.name,
                        machineType: element.machine.type === 'washer' ? 'เครื่องซัก' : ( element.machine.type === 'dryer' ? 'เครื่องอบ' : element.machine.type),
                        branchCode: element.machine.branch.code,
                        branchName: element.machine.branch.name,
                        customerId: element.customerId,
                        customerPhone: element.customer.phone,
                        checkoutType: element.checkoutType,
                        paymentName: element.paymentTransaction ? element.paymentTransaction.paymentName : '',
                        chargeId: element.paymentTransaction ? element.paymentTransaction.chargeId : '',
                        walletId: element.walletId,
                        pointWalletId: element.pointWalletId,
                        pointRedeem: element.pointRedeem,
                        couponCode: element.coupon ? element.coupon.code : '',
                        couponValue: element.couponValue ? element.couponValue : '',
                        amount: element.amount,
                        discount: element.discount,
                        totalAmount: element.totalAmount,
                        createdAt: moment(element.createdAt).format('DD/MM/YYYY H:mm'),
                        machineTime: element.machineTime ? element.machineTime : '',
                        status: "SUCCESSFUL"
                    }
                    totalIncomeApp = totalIncomeApp + element.totalAmount;
                    laundryData.push(laundryRecord)
                }

                for(let element of sqIncomeResult){
                    const sqIncomeRecord = {
                        incomeDate: moment(element.incomeDate).format('DD/MM/YYYY'),
                        incomeTotal: element.incomeTotal
                    }
                    totalIncomeCoin = totalIncomeCoin + element.incomeTotal
                    sqIncomeData.push(sqIncomeRecord)
                }

                //add summary data

                const topicSummary = {
                    topic: "รายได้และค่าบริการสาขา "+branchName+" ปี: "+fanshineYear+" เดือน "+months_th[fanshineMonth],
                    value: ""
                }
                summaryData.push(topicSummary)

                const headerSummary = {
                    topic: "หัวข้อ",
                    value: "จำนวนเงิน"
                }
                summaryData.push(headerSummary)

                var summaryDataRecord = "";
                summaryDataRecord = { topic: "รายได้จากแอปพลิเคชัน", value: totalIncomeApp }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "รายได้จากเหรียญ", value: totalIncomeCoin }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "รายได้ทั้งหมด", value: totalIncomeCoin+totalIncomeApp }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "ค่าบริการ Marketing Fee ("+fanshineFee+"% )", value: ( (totalIncomeCoin+totalIncomeApp) * fanshineFee /100 ) }
                summaryData.push(summaryDataRecord)
                
                summaryDataRecord = { topic: "", value: "" }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "รายได้สุทธิ", value: (totalIncomeCoin+totalIncomeApp) - ( (totalIncomeCoin+totalIncomeApp) * fanshineFee /100 ) }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "", value: "" }
                summaryData.push(summaryDataRecord);

                summaryDataRecord = { topic: "ยอดเงินที่เบิกได้ของเดือนี้", value: (totalIncomeApp) - ( (totalIncomeCoin+totalIncomeApp) * fanshineFee /100 ) }
                summaryData.push(summaryDataRecord);




                // add width
                var wscols = []
                for(let indexWidth = 0 ; indexWidth < 23 ; indexWidth++){
                    wscols.push({wch:20})
                }

                var wscols2 = []
                for(let indexWidth = 0 ; indexWidth < 2 ; indexWidth++){
                    wscols2.push({wch:20})
                }

                var wscols3 = []
                for(let indexWidth = 0 ; indexWidth < 2 ; indexWidth++){
                    wscols3.push({wch:50})
                }

                const merge = [
                    { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
                ];

                const workbook = XLSX.utils.book_new()
                const worksheet = XLSX.utils.json_to_sheet(laundryData, {skipHeader:true})
                worksheet['!cols'] = wscols;

                const worksheet2 = XLSX.utils.json_to_sheet(sqIncomeData, {skipHeader:true})
                worksheet2['!cols'] = wscols2;

                const worksheet3 = XLSX.utils.json_to_sheet(summaryData, {skipHeader:true})
                worksheet3['!cols'] = wscols3;
                worksheet3['!merges'] = merge;

  

            
                XLSX.utils.book_append_sheet(workbook, worksheet3, 'รายได้โดยสรุป')
                XLSX.utils.book_append_sheet(workbook, worksheet, 'ประวัติการชำระผ่านแอปพลิเคชัน')
                XLSX.utils.book_append_sheet(workbook, worksheet2, 'รายได้จากเหรียญ')
                XLSX.writeFile(workbook, 'รายงานรายได้แฟรนไชส์สาขา'+branchName+' ปี '+fanshineYear+ ' เดือน ' + months_th[fanshineMonth]+'.xlsx')


            }
        }


        setLoadingFanshine(false)

    }

    

  const fetchReportOmise = async (startDate, endDate) => {

    try {
      const response = await axios.get(`${API_URL}reportOmise`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
            startDate: startDate,
            endDate: endDate,
          }
      })

    return response.data

    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchReportWallet = async (startDate, endDate) => {

    try {
      const response = await axios.get(`${API_URL}reportWallet`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
            startDate: startDate,
            endDate: endDate,
          }
      })

    return response.data

    } catch (error) {
      console.log('error', error)
    }
  }
  
  const fetchReportTransaction = async (startDate, endDate) => {

    try {
      const response = await axios.get(`${API_URL}reportTransaction`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        params: {
            startDate: startDate,
            endDate: endDate,
          }
      })

    return response.data

    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchReportFanshine = async (fanshineBranch, fanshineYear, fanshineMonth) => {

    try {
        const response = await axios.post(`${API_URL}reportFanshine`, 
        {
            branch: fanshineBranch,
            year: fanshineYear,
            month: fanshineMonth
        },{
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
  
      return response.data
  
      } catch (error) {
        console.log('error', error)
      }
  }

  
  const setOmiseDateRange = (value,dateString) =>{
    setOmiseStartDate(dateString[0])
    setOmiseEndDate(dateString[1])
  }

  const setWalletDateRange = (value,dateString) =>{
    setWalletStartDate(dateString[0])
    setWalletEndDate(dateString[1])
  }

  const setTransactionDateRange = (value,dateString) =>{
    setTransactionStartDate(dateString[0])
    setTransactionEndDate(dateString[1])
  }

  const setFanshineBranchSelected = (value) => {
    setFanshineBranch(value);
  }

  const setFanshineYearSelected = (value) => {
    setFanshineYear(value);
  }

  const setFanshineMonthSelected = (value) => {
    setFanshineMonth(value);
  }



  useEffect(() => {
    getBranch();
  },[])

    const getBranch = async () => {
        try {
            const response = await axios.get(`${API_URL}getListFanshine`,
                {
                    headers: { 'Authorization': `bearer ${token}` }
                })
            setBranch(response.data);

        } catch (error) {
            console.log(error);
        }
    }


  return (
    <div>
    {contextHolder}
    {
        <Modal
        title='รายงาน Omise'
        centered
        open={displayOmise}
        width={400}
        onCancel={handleOmiseCancel}
        onOk={handleOmiseOk}
        >
            <RangePicker onChange={setOmiseDateRange}/>
        </Modal>
    }
    {
        <Modal
        title='รายงาน กระเป๋าเงิน'
        centered
        open={displayWallet}
        width={400}
        onCancel={handleWalletCancel}
        onOk={handleWalletOk}
        >
            <RangePicker onChange={setWalletDateRange}/>
        </Modal>
    }
    {
        <Modal
        title='รายงาน ประวัติการใช้งานเครื่อง'
        centered
        open={displayTransaction}
        width={400}
        onCancel={handleTransactionCancel}
        onOk={handleTransactionOk}
        >
            <RangePicker onChange={setTransactionDateRange}/>
        </Modal>
    }
    {
        <Modal
        title='รายงาน รายได้สาขาแฟรนไชส์และค่าบริการ'
        centered
        open={displayFanshine}
        width={400}
        onCancel={handleFanshineCancel}
        onOk={handleFanshineOk}
        >
            
             
            สาขา:   <Select
                        allowClear
                        optionFilterProp="label"
                        onChange={setFanshineBranchSelected}
                        style={{width:150}}>
                        {branch ? branch.map(branchItem => (
                            <Select.Option key={branchItem.id} value={branchItem.branchId} label={branchItem.branchName}>{branchItem.branchName}</Select.Option>
                        )) : ''}
                    </Select>
            <br/><br/>
            ปี: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Select
                        allowClear
                        onChange={setFanshineYearSelected}
                        style={{width:150}}>
                            {listYear ? listYear.map(thisYear => (
                                    <Select.Option key={thisYear} value={thisYear} label={thisYear}>{thisYear}</Select.Option>
                            )) : ''}
                </Select>
                <br/><br/>
            เดือน: <Select
                        allowClear
                        onChange={setFanshineMonthSelected}
                        style={{width:150}}>
                            {months_th ? months_th.map((thisMonth, index) => (
                                <Select.Option key={index} value={index} label={thisMonth}>{thisMonth}</Select.Option>
                            )) : ''}
                </Select>
                <br/><br/>

        </Modal>
    }

    <Card title='ข้อมูล Omise'>
        <Button onClick={ () => showOmisePickDate() } loading={loadingOmise}><DownloadOutlined /> ดาวน์โหลด</Button>
    </Card>
    <br /><br />
    <Card title='ข้อมูล กระเป๋าเงิน'>
        <Button onClick={ () => showWalletPickDate() } loading={loadingWallet}><DownloadOutlined /> ดาวน์โหลด</Button>
    </Card>
    <br /><br />
    <Card title='ข้อมูล ประวัติการใช้งานเครื่อง'>
        <Button onClick={ () => showTransactionPickDate() } loading={loadingTransaction}><DownloadOutlined /> ดาวน์โหลด</Button>
    </Card>
    <br /><br />
    <Card title='ข้อมูล รายได้สาขาแฟรนไชส์และค่าบริการ'>
        <Button onClick={ () => showFanshinePickDate() } loading={loadingFanshine}><DownloadOutlined /> ดาวน์โหลด</Button>
    </Card>
    </div>
  )
}

export default AccountReportDownload