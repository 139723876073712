import React, { useContext, useEffect, useState } from "react";

import { Layout} from "antd";

import MenuSide from "../components/menuSide";
import ListLaundry from "../components/listLaundry"

import { AppContext } from '../App';

const { Header, Content } = Layout;

const Transaction = () => {

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <ListLaundry />
          </Content>
          
      </Layout>
    )
  };
  
  export default Transaction;
  