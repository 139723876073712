import React, { useContext, useEffect, useState } from "react";

import { Layout, theme, Tabs, Typography, Row, Col, Space } from "antd";
import { ConsoleSqlOutlined  } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import AccountReportDownload from "../components/accountReportDownload"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;


const AccountReport = () => {

  const { user } = useContext(AppContext);
  if(user){ 
    if(user.role !== 'admin'){      
      window.location.href = "/home"; 
    }
  }
  

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
          <Row>
                    <Col span={1} />
                    <Col span={22}>
                      <Title level={3} ><ConsoleSqlOutlined /> รายงานบัญชี</Title>
                    </Col>
                    <Col span={1} />

            </Row>
            <Row>
                    <Col span={1} />
                    <Col span={22}>
                      <AccountReportDownload />
                    </Col>
                    <Col span={1} />

            </Row>
          </Content>
          
      </Layout>
    )
  };
  
  export default AccountReport;
  