import React, { useContext, useEffect, useState } from "react";
import { Layout, Typography, Row, Col, Button} from "antd";

import MenuSide from "../components/menuSide";
import ListMachine from "../components/listMachine"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const Transaction = () => {

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <Row>
              <Col span={1} ></Col>
              <Col span={22} ><ListMachine /></Col>
              <Col span={1} ></Col>
            </Row>
            
          </Content>
          
      </Layout>
    )
  };
  
  export default Transaction;
  