import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography,Row, Col,} from "antd";
import { ShopOutlined } from '@ant-design/icons';

import MenuSide from "../components/menuSide";
import ListBranchDetail from "../components/listBranchDetail"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const Transaction = () => {

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">
            <Row>
                <Col span={1} />
                <Col span={22} >
                    <Title level={3} ><ShopOutlined /> สาขา</Title>
                </Col>
                <Col span={1} />
            </Row>
            <Row>
                <Col span={1} />
                <Col span={22} >
                    <ListBranchDetail />
                </Col>
                <Col span={1} />
            </Row>
            



          </Content>
          
      </Layout>
    )
  };
  
  export default Transaction;
  