
import React, { useContext, useEffect, useState } from "react";

import { Layout, Typography,Row, Col} from "antd";

import MenuSide from "../components/menuSide";
import ListPromotionBranch from "../components/ListPromotionBranch"

import { AppContext } from '../App';

const { Header, Content } = Layout;
const { Title } = Typography;

const promotionBranch = () => {

    return (
      <Layout className="fullHeight">
          <MenuSide />
          <Content className="contentSection">

            <Row>
                <Col span={1} />
                <Col span={22} >
                    <ListPromotionBranch />
                </Col>
                <Col span={1} />
            </Row>
            
          </Content>
          
      </Layout>
    )
  };
  
  export default promotionBranch;
  